
  .gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;
    margin-left: 10%;
width: 80%;
    max-width: 700px;
    padding: 0.5em;
  }
  .gallery-quote {
    display: flex;
    align-items: center;
  }
  .gallery-quote-sub{ 
    font-size: 40px;
    position: fixed; 
    margin-left: -5rem; 
  }
  .gallery-avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: #f3f3f3;
    /* margin-right: 40px; */
  }
  
  .gallery-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .gallery-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .gallery-quote {
    /* font-size: 24px; */
    font-weight: 500;
    line-height: 1.3;
    color: #707070;
    margin-bottom: 20px;
  }
  
  .gallery-name {
    font-size: 18px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 5px;
  }
  
  .gallery-job-title {
    font-size: 14px;
    font-weight: 400;
    color: #707070;
  } 
  

/* Swiper gallerys */
.swiper-container {
margin: 0 auto;
width: 100%;
max-width: 1050px;
margin-top: 2.5rem;
}

.swiper-slide {
width: 100%;
}

.swiper-pagination {
margin-top: 20px;
display: flex;
justify-content: center;
position: relative;
}

.swiper-pagination-bullet {
width: 8px;
height: 8px;
border-radius: 50%;
margin: 5px 5px;
background-color: #ccc;
}

.swiper-pagination-bullet-active {
background-color: #000;
}
.swiper-pagination-bullet {
background-color:  #171717;;
}
.swiper-button-next::after,
.swiper-button-prev::after {
width: 10px;
height: 10px;
font-size: 24px;
} 
.swiper-button-next,
.swiper-button-prev {
/* background-color: rgba(255, 255, 255, 0.7);
 */
/* width: 30px;
height: 30px;
position: absolute;
top: 50%;
transform: translateY(-50%);
z-index: 10;
cursor: pointer;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
transition: background-color 0.2s ease-in-out; */
color: #000;
}

@media only screen and (min-width: 768px) {
.gallery{
flex-direction: row;
/* margin-left: 10%; */
}
.gallery-avatar-container{
margin-right: 30px;
padding: 10px;
}
.gallery-quote {
/* display: flex; */
align-items: baseline;
}
.gallery-quote-sub{ 
font-size: 40px;
position: fixed;
margin-left: -1.2em;
}
}

.locate-us-container {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    max-width: 1120px;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
  }
  
  .locate-us-info {
    width: 100%;
    max-width: 50%;
    margin-right: 2rem;
  }
  .links {
    color: #171717;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .locate-us-links {
    margin-left: 1em;
  }
  
  .locate-us-info h2 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .locate-us-info p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .locate-us-form {
    width: 100%;
    max-width: 600px;
  }
  
  .locate-us-form form {
    display: flex;
    flex-direction: column;
  }
/*   
  .locate-us-form label {
    font-size: 16px;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  
  .locate-us-form input,
  .locate-us-form textarea {
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: #f8f8f8;
    padding: 0.5rem;
    font-size: 16px;
   margin-bottom: 1rem; } 
  
  .locate-us-form input:focus,
  .locate-us-form textarea:focus {
    outline: none;
  }
  
  .locate-us-form textarea {
    height: 10rem;
  }
  
  .locate-us-form button {
    /* background-color: #171717;
    color: white; */
    /* border: none; 
    border-radius: 0;
    /* padding: 0.5rem 1rem; 
    font-size: 16px;
    cursor: pointer;
    margin-top: 1rem;
    background-color: #171717;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  
  }
  
  .locate-us-form button:hover {
    /* background-color: #3e8e41; 
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: #171717;
    border: 1px solid #171717;
    transition: 250ms;
  }
  .error {
    display: block;
    margin-top: 5px;
    color: red;
    font-size: 12px;
    font-weight: 100;
  }
  .image {
    width: -webkit-fill-available;
  }
  */
  
  @media only screen and (min-width: 768px) {
    .locate-us-container {
      flex-flow: row;
      justify-content: center;
    }
    .image {
        width: -webkit-fill-available;
        width: 100%;
      }
  
    .locate-us-info {
      margin-right: 2rem;
    }
  
    .locate-us-form {
      margin-left: 2rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image {
        width: -webkit-fill-available;
        width: 100%;
      }
    .locate-us-info,
    .locate-us-form {
      width: 100%;
      max-width: none;
      margin-right: 0;
      margin-left: 0;
      padding-bottom: 50px;
    }
  }
  
.team {
  margin: 0 auto;
  max-width: 1120px;
  justify-content: space-between;
  align-items: center;
}
.team-cards {
  display: grid;
  grid-auto-flow: column;
  list-style: none;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding-left: 1rem;
  overflow-y: hidden;
  padding-bottom: 3rem;
}

.team-cards::-webkit-scrollbar {
  height: 0;
}

.team-cards::-webkit-scrollbar-thumb,
.team-cards::-webkit-scrollbar-track {
  border-radius: 92px;
}

.team-cards::-webkit-scrollbar-thumb {
  background: #dd7d25;
}

.team-cards::-webkit-scrollbar-track {
  background: #edf2f4;
}
.team-card-image {
  width: 100px;
  height: 150px;
}
.team-header {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}
.team-subheader {
  margin-top: 1rem;
  text-align: left;
  font-size: 1.5rem;
  margin-left: 2rem;
}
/* .team-card-name {
  /* position: relative;
  margin-top: -2.5em;
  margin-left: 0.5em;
  font-size: 1.5rem;
  color: #edf2f4; 
} */
.team-card-name-underline {
  height: 0.2em;
  width: 3em;
  position: relative;
  /* margin-top: -0.5em; */
  margin-left: 0.5em;
  font-size: 1.5em;
  background-color: #dd7d25;
}

  
  .about-us-container {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    max-width: 1120px;
    justify-content: space-between;
    align-items: center;
    padding: 5rem 2rem;
  }
  
  .about-us-info {
    width: 100%;
    max-width: 50%;
    margin-right: 2rem;
  }
  
  .about-us-info h2 {
    font-size: 24px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .about-us-info p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .about-us-form {
    width: 100%;
    max-width: 600px;
  }
  
  /* .about-us-form form {
    display: flex;
    flex-direction: column;
  }
  
  .about-us-form label {
    font-size: 16px;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  
  .about-us-form input,
  .about-us-form textarea {
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: #f8f8f8;
    padding: 0.5rem;
    font-size: 16px;
    /* margin-bottom: 1rem; 
  }
  
  .about-us-form input:focus,
  .about-us-form textarea:focus {
    outline: none;
  }
  
  .about-us-form textarea {
    height: 10rem;
  }
  
  .about-us-form button {
    /* background-color: #171717;
    color: white; 
    /* border: none; 
    border-radius: 0;
    /* padding: 0.5rem 1rem; 
    font-size: 16px;
    cursor: pointer;
    margin-top: 1rem;
    background-color: #171717;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  
  }
  
  .about-us-form button:hover {
    /* background-color: #3e8e41;
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: #171717;
    border: 1px solid #171717;
    transition: 250ms;
  }
  .error {
    display: block;
    margin-top: 5px;
    color: red;
    font-size: 12px;
    font-weight: 100;
  } */
  /* .image {
    width: -webkit-fill-available;
  } */
  .about-us-numbers {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    max-width: 1120px;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    padding-bottom: 6rem;
  }
  
  @media only screen and (min-width: 768px) {
    .about-us-container {
      flex-flow: row;
      justify-content: center;
    }
    .about-us-numbers {
      flex-flow: row;
      justify-content: center;
    }
    /* .image {
        width: -webkit-fill-available;
        width: 100%;
      } */
  
    .about-us-info {
      margin-right: 2rem;
    }
  
    .about-us-form {
      margin-left: 2rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    /* .image {
        width: -webkit-fill-available;
        width: 100%;
      } */
    .about-us-info,
    .about-us-form {
      width: 100%;
      max-width: none;
      margin-right: 0;
      margin-left: 0;
      /* padding-bottom: 50px; */
    }
    .about-us-numbers {
      flex-flow: wrap;
      justify-content: center;
    }
    .team-subheader {
      margin-left: 1rem;
    }
    .team-cards {
      padding-left: 0.5rem;
    }
    
  }
  
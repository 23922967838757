
.slider {
  /* background: #fff; */
  width: 100%;
  /* min-height: 50vh; */
  padding: 10px 0;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* transition: opacity 0.5s ease-in-out; */
}

.item-wrap {
  display: flex;
  width: 100%;
}

.item {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  width: 240px;
  height: 240px;
  font-size: 14px;
  border: 2px solid #d1d1d1;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}
* {
  box-sizing: border-box;
}
.footer-wrapper {
  /* background: #ccc; */
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #fff; */
  /* font-size: 10vw; */
}
.about-us-container {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    max-width: 1120px;
    justify-content: space-between;
    align-items: center;
    padding: 5rem 2rem;
  }
  
  .about-us-info {
    width: 100%;
    max-width: 50%;
    margin-right: 2rem;
  }
  
  .about-us-info h2 {
    font-size: 24px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .about-us-info p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .about-us-form {
    width: 100%;
    max-width: 600px;
  }
  
  /* .about-us-form form {
    display: flex;
    flex-direction: column;
  }
  
  .about-us-form label {
    font-size: 16px;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  
  .about-us-form input,
  .about-us-form textarea {
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: #f8f8f8;
    padding: 0.5rem;
    font-size: 16px;
    /* margin-bottom: 1rem; 
  }
  
  .about-us-form input:focus,
  .about-us-form textarea:focus {
    outline: none;
  }
  
  .about-us-form textarea {
    height: 10rem;
  }
  
  .about-us-form button {
    /* background-color: #171717;
    color: white; 
    /* border: none; 
    border-radius: 0;
    /* padding: 0.5rem 1rem; 
    font-size: 16px;
    cursor: pointer;
    margin-top: 1rem;
    background-color: #171717;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  
  }
  
  .about-us-form button:hover {
    /* background-color: #3e8e41;
    transition: all 0.3s ease-out;
    background-color: #fff;
    color: #171717;
    border: 1px solid #171717;
    transition: 250ms;
  }
  .error {
    display: block;
    margin-top: 5px;
    color: red;
    font-size: 12px;
    font-weight: 100;
  } */
  .image {
    width: -webkit-fill-available;
  }
  .about-us-numbers {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    max-width: 1120px;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    padding-bottom: 4rem;
  }
  .numbers-container {
    margin: 0 0.5rem;
    padding: 0.5rem 0.5rem;
    background-color: #171717;
    color: #fff;
    text-align: center;
    width: -webkit-fill-available;
    margin-bottom: 0.5rem;
  }
  .numbers-span {
    font-size: 30px;
    position: absolute;
  }
  
  @media only screen and (min-width: 768px) {
    .about-us-container {
      flex-flow: row;
      justify-content: center;
    }
    .about-us-numbers {
      flex-flow: row;
      justify-content: center;
    }
    .image {
        width: -webkit-fill-available;
        width: 100%;
      }
  
    .about-us-info {
      margin-right: 2rem;
    }
  
    .about-us-form {
      margin-left: 2rem;
    }
    .numbers-container {
      margin: 0 0.5rem;
      background-color: #171717;
      color: #fff;
      padding: 0.5rem 2rem;
      width: auto;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .image {
        width: -webkit-fill-available;
        width: 100%;
      }
    .about-us-info,
    .about-us-form {
      width: 100%;
      max-width: none;
      margin-right: 0;
      margin-left: 0;
      /* padding-bottom: 50px; */
    }
    .about-us-numbers {
      flex-flow: wrap;
      justify-content: center;
    }
  }
  
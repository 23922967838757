.contact-us-container {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  max-width: 1120px;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 2rem;
}

.contact-us-info {
  width: 100%;
  max-width: 600px;
  margin-right: 2rem;
}
.links {
  color: #171717;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.contact-us-links {
  margin-left: 1em;
}

.contact-us-info h2 {
  font-size: 24px;
  margin-bottom: 1rem;
}

.contact-us-info p {
  font-size: 16px;
  line-height: 1.5;
}

.contact-us-form {
  width: 100%;
  max-width: 600px;
}

.contact-us-form form {
  display: flex;
  flex-direction: column;
}

.contact-us-form label {
  font-size: 16px;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.contact-us-form input,
.contact-us-form textarea {
  border: 1px solid #ccc;
  border-radius: 0;
  background-color: #f8f8f8;
  padding: 0.5rem;
  font-size: 16px;
  /* margin-bottom: 1rem; */
}

.contact-us-form input:focus,
.contact-us-form textarea:focus {
  outline: none;
}

.contact-us-form textarea {
  height: 10rem;
}

.contact-us-form button {
  /* background-color: #171717;
  color: white; */
  /* border: none; */
  border-radius: 0;
  /* padding: 0.5rem 1rem; */
  font-size: 16px;
  cursor: pointer;
  margin-top: 1rem;
  background-color: #171717;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;

}

.contact-us-form button:hover {
  /* background-color: #3e8e41; */
  transition: all 0.3s ease-out;
  background-color: #fff;
  color: #171717;
  border: 1px solid #171717;
  transition: 250ms;
}
.error {
  display: block;
  margin-top: 5px;
  color: red;
  font-size: 12px;
  font-weight: 100;
}
/* .loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #666;
  padding: 1rem;
} */
.success-container {
  display: grid;
  place-items: center;
}
.loading-container {
  display: grid;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  content: '';
  box-sizing: border-box;
  /* position: relative; */
  top: 50%;
  left: 50%;
  width: 3rem;
  height: 3rem;
  /* margin-top: -1rem; */
  margin-left: 1rem;
  margin-bottom: 1rem;
  border-radius: 50%;
  border-top: 3px solid #666;
  border-right: 3px solid transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (min-width: 768px) {
  .contact-us-container {
    flex-flow: row;
    justify-content: center;
  }

  .contact-us-info {
    margin-right: 2rem;
  }

  .contact-us-form {
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .contact-us-info,
  .contact-us-form {
    width: 100%;
    max-width: none;
    margin-right: 0;
    margin-left: 0;
    padding-bottom: 50px;
  }
}

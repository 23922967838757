
.home-main-container{
    background: white;
}
 .home-container {
      display: flex;
      flex-flow: column;
      margin: 0 auto;
      max-width: 1120px;
      justify-content: space-between;
      align-items: center;
      padding: 5rem 2rem;
    }
    
    .home-info {
      width: 100%;
      max-width: 50%;
      margin-right: 2rem;
    }
    
    .home-info h2 {
      font-size: 24px;
      margin-bottom: 1rem;
      text-align: center;
    }
    
    .home-info p {
      font-size: 16px;
      line-height: 1.5;
    }
    
    .home-form {
      width: 100%;
      max-width: 600px;
    }
    .home-form button {
      /* background-color: #171717;
      color: white; */
      /* border: none; */
      border-radius: 0;
      /* padding: 0.5rem 1rem; */
      font-size: 16px;
      cursor: pointer;
      margin-top: 1rem;
      background-color: #171717;
      color: #fff;
      padding: 8px 20px;
      border: 1px solid var(--primary);
      transition: all 0.3s ease-out;
    
    }
    
    .home-form button:hover {
      /* background-color: #3e8e41; */
      transition: all 0.3s ease-out;
      background-color: #fff;
      color: #171717;
      border: 1px solid #171717;
      transition: 250ms;
    }
    /* .testimonials-container{
      background-color: #333333;
    } */
    /* .testimonial {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        width: 90%;
        max-width: 700px;
      }
      
      .testimonial-avatar-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #f3f3f3;
        margin-right: 40px;
      }
      
      .testimonial-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
      
      .testimonial-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      .testimonial-quote {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.3;
        color: #707070;
        margin-bottom: 20px;
      }
      
      .testimonial-name {
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        margin-bottom: 5px;
      }
      
      .testimonial-job-title {
        font-size: 16px;
        font-weight: 400;
        color: #707070;
      } */
      
    /* .home-form form {
      display: flex;
      flex-direction: column;
    }
    
    .home-form label {
      font-size: 16px;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }
    
    .home-form input,
    .home-form textarea {
      border: 1px solid #ccc;
      border-radius: 0;
      background-color: #f8f8f8;
      padding: 0.5rem;
      font-size: 16px;
      /* margin-bottom: 1rem; 
    }
    
    .home-form input:focus,
    .home-form textarea:focus {
      outline: none;
    }
    
    .home-form textarea {
      height: 10rem;
    }
    
    .home-form button {
      /* background-color: #171717;
      color: white; 
      /* border: none; 
      border-radius: 0;
      /* padding: 0.5rem 1rem; 
      font-size: 16px;
      cursor: pointer;
      margin-top: 1rem;
      background-color: #171717;
      color: #fff;
      padding: 8px 20px;
      border: 1px solid var(--primary);
      transition: all 0.3s ease-out;
    
    }
    
    .home-form button:hover {
      /* background-color: #3e8e41;
      transition: all 0.3s ease-out;
      background-color: #fff;
      color: #171717;
      border: 1px solid #171717;
      transition: 250ms;
    }
    .error {
      display: block;
      margin-top: 5px;
      color: red;
      font-size: 12px;
      font-weight: 100;
    } */
    .image {
      width: -webkit-fill-available;
    }
    .home-numbers {
      display: flex;
      flex-flow: column;
      margin: 0 auto;
      max-width: 1120px;
      justify-content: space-between;
      align-items: center;
      padding: 0rem 2rem;
      padding-bottom: 4rem;
    }
    .numbers-container {
      margin: 0 0.5rem;
      padding: 0.5rem 0.5rem;
      background-color: #171717;
      color: #fff;
      text-align: center;
      width: -webkit-fill-available;
      margin-bottom: 0.5rem;
    }
    .numbers-span {
      font-size: 30px;
      position: absolute;
    }
    
    @media only screen and (min-width: 768px) {
      .home-container {
        flex-flow: row;
        justify-content: center;
      }
      .home-numbers {
        flex-flow: row;
        justify-content: center;
      }
      .image {
          width: -webkit-fill-available;
          width: 100%;
        }
    
      .home-info {
        margin-right: 2rem;
      }
    
      .home-form {
        margin-left: 2rem;
      }
      .numbers-container {
        margin: 0 0.5rem;
        background-color: #171717;
        color: #fff;
        padding: 0.5rem 2rem;
        width: auto;
      }
    }
    
    @media only screen and (max-width: 767px) {
      .image {
          width: -webkit-fill-available;
          width: 100%;
        }
      .home-info,
      .home-form {
        width: 100%;
        max-width: none;
        margin-right: 0;
        margin-left: 0;
        /* padding-bottom: 50px; */
      }
      .home-numbers {
        flex-flow: wrap;
        justify-content: center;
      }
    }
    
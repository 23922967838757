video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .top-section-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
    /* top:62%; */
  }
  
  .top-section-container > h1 {
    /* color: #fff; */
    font-size: 100px;
    margin-top: -100px;
    position: fixed;
    z-index: -1;
  }
  
  .top-section-container > p {
    margin-top: 40px;
    /* color: #fff; */
    font-size: 28px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif; */
      font-family: 'Poppins';
      position: fixed;
      z-index: -1;
      /* top:60%; */
  }
  
  .top-section-btns {
    margin-top: 32px;
  }
  
  .top-section-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .top-section-container > h1 {
      font-size: 70px;
      margin-top: -150px;
      /* position: fixed;
      z-index: -1; */
    }
  }
  
  @media screen and (max-width: 768px) {
    .top-section-container > h1 {
      font-size: 50px;
      margin-top: -100px;
      /* position: fixed;
      z-index: -1; */
    }
  
    .top-section-container > p {
      font-size: 18px;
      /* position: fixed;
      z-index: -1; */
      margin-top: 0px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  
/* Testimonials */
/* .testimonial {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .testimonial-avatar-container {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  
  .testimonial-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .testimonial-content {
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-quote {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  
  .testimonial-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .testimonial-job-title {
    font-size: 14px;
    font-weight: 500;
  } */
  .testimonial {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-bottom: 10%; */
        margin-left: 10%;
    width: 85%;
        /* max-width: 700px; */
        padding: 0.5em;
      }
      .testimonial-quote {
        display: flex;
        align-items: center;
      }
      .testimonial-quote-sub{ 
        font-size: 40px;
        position: fixed; 
        margin-left: -5rem; 
      }
      .testimonial-avatar-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: #f3f3f3;
        /* margin-right: 40px; */
      }
      
      .testimonial-avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-fit: cover;
      }
      
      .testimonial-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      .testimonial-quote {
        /* font-size: 24px; */
        font-weight: 500;
        line-height: 1.3;
        color: #707070;
        margin-bottom: 20px;
      }
      
      .testimonial-name {
        font-size: 18px;
        font-weight: 700;
        color: #333333;
        margin-bottom: 5px;
      }
      
      .testimonial-job-title {
        font-size: 14px;
        font-weight: 400;
        color: #707070;
      } 
      
  
  /* Swiper Testimonials */
  .swiper-container {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    margin-top: 2.5rem;
  }
  
  .swiper-slide {
    width: 100%;
  }
  
  .swiper-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #ccc;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #000;
  }
  .swiper-pagination-bullet {
    background-color:  #171717;;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 10px;
    height: 10px;
    font-size: 24px;
  } 
  .swiper-button-next,
  .swiper-button-prev {
    /* background-color: rgba(255, 255, 255, 0.7);
     */
    /* width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-in-out; */
    color: #000;
  }
  
  /* .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .swiper-button-next::before,
  .swiper-button-prev::before {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(45deg);
  } 
   */
  /* .swiper-button-next {
    right: 0;
  }
  
  .swiper-button-prev {
    left: 0;
  } */
  
  /* @media (max-width: 576px) {
    .testimonial {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .testimonial-avatar-container {
      margin-right: 0;
      margin-bottom: 15px;
    }
  
    .testimonial-content {
      align-items: center;
    }
  } */
  @media only screen and (min-width: 768px) {
.testimonial{
  flex-direction: row;
  /* margin-left: 10%; */
}
.testimonial-avatar-container{
margin-right: 30px;
padding: 10px;
}
.testimonial-quote {
  /* display: flex; */
  align-items: baseline;
}
.testimonial-quote-sub{ 
  font-size: 40px;
  position: fixed;
  margin-left: -1.2em;
}
  }
  